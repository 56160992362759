<template>
	<el-tabs v-model="Name" type="card" @tab-click="handleClicktab">
		<el-tab-pane label="手动录入" name="first" class="margin-lr-lg">
			<el-form :model="Information" :rules="rules" ref="Information" label-width="96px" class="demo-ruleForm"
				v-loading="loading" label-position="left">
				<div class="flex  justify-content-between">
					<el-form-item label="客户姓名" prop="UserName" style="width: 25%;">
						<el-input v-model="Information.UserName" maxlength="10"></el-input>
					</el-form-item>
					<el-form-item label="客户简称" style="width: 25%;">
						<el-input v-model="Information.Abbreviation "></el-input>
					</el-form-item>
					<el-form-item label="客户进展" style="width: 25%;">
						<el-select v-model="Information.Progress" placeholder="请选择" style="width: 100%;">
							<el-option label="新入库" :value="1"></el-option>
							<el-option label="沟通/邀约" :value="2"></el-option>
							<el-option label="上门咨询" :value="3"></el-option>
							<el-option label="异议排除" :value="4"></el-option>
							<el-option label="报名入学" :value="5"></el-option>
						</el-select>
					</el-form-item>
				</div>

				<div class="flex justify-content-between">
					<el-form-item label="性别" style="width: 25%;">
						<el-radio v-model="Information.Sex" :label="item.value" v-for="item of genderList"
							:key="item.value">{{item.label}}</el-radio>
					</el-form-item>
					<el-form-item label="生日" style="width: 25%;">
						<el-date-picker type="date" placeholder="选择日期" v-model="Information.Birthday "
							style="width: 100%;">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="来源"  prop="Source" style="width: 25%;">
						<el-select v-model="Information.Source" placeholder="请选择" style="width: 100%;">
							<el-option :label="item.sub_title" :value="index+1" v-for="(item,index) of sourceList"
								:key="index"></el-option>
						</el-select>
						<el-button type="primary" size="mini" @click="editHandle">新建来源</el-button>
						<add-source :Visible="editVisible" @closepop="closeEditPop"></add-source>
					</el-form-item>
				</div>

				<div class="flex  justify-content-between">
					<el-form-item label="微信" style="width: 25%;">
						<el-input v-model="Information.WeChat"></el-input>
					</el-form-item>

					<el-form-item label="QQ" style="width: 25%;">
						<el-input v-model="Information.QQ"></el-input>
					</el-form-item>
					<el-form-item label="邮箱" style="width: 25%;" prop="Mail">
						<el-input v-model="Information.Mail"></el-input>
					</el-form-item>
				</div>

				<div class="flex justify-content-between">
					<el-form-item label="客户分组" style="width: 25%;">
						<el-select v-model="Information.Grouping " placeholder="请选择" style="width: 100%;">
							<el-option :label="item.GroupName" :value="item.id"
								v-for="(item,index) of datalist_enterprise" :key="index"></el-option>
						</el-select>
						<el-button type="primary" size="mini" @click="editHandle">新建分组</el-button>
						<addGroup :Visible="editVisible1" @closepop="closeEditPop"></addGroup>
					</el-form-item>
					<el-form-item label="标签" style="width: 62.5%;">
						<el-button type="primary" size="mini" icon="el-icon-plus" @click="chooselable(0)"
							v-if="!clientList.length">添加</el-button>
						<div class="flex flex-wrap-wrap" v-else>
							<div class="btn-bcolor-b Clientbtn-label myClient-left-10 margin-top-xs"
								v-for="item in clientList" :label="item">{{item}}</div>
							<el-button type="primary" icon="el-icon-plus" @click="chooselable(1,clientList)"
								style="margin-left: 10px; margin-top: 5px;" size="mini">重新添加</el-button>
						</div>
					</el-form-item>
				</div>


				<div class="flex justify-content-between">
					<el-form-item label="手机号1" style="width: 25%;" prop="PhoneNumber1">
						<el-input v-model="Information.PhoneNumber1 "></el-input>
					</el-form-item>

					<el-form-item label="手机号2" style="width: 25%;">
						<el-input v-model="Information.PhoneNumber2 "></el-input>
					</el-form-item>
					<el-form-item label="座机" style="width: 25%;">
						<el-input v-model="Information.FixedTelephone "></el-input>
					</el-form-item>
				</div>

				<div class="flex justify-content-between">
					<el-form-item label="就读学校" style="width: 40%;" prop="School">
						<el-input type="textarea" :rows="5" v-model="Information.School " placeholder="1000个字以内"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
					<el-form-item label="年级" style="width: 40%;" prop="Grade">
						<el-input type="textarea" :rows="5" v-model="Information.Grade " placeholder="1000个字以内"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
				</div>

				<div class="flex justify-content-between">
					<el-form-item label="工作单位" style="width: 40%;">
						<el-input v-model="Information.WorkUnit "></el-input>
					</el-form-item>
					<el-form-item label="地址" style="width: 40%;">
						<el-input v-model="Information.Address "></el-input>
					</el-form-item>
				</div>
				<div class="flex justify-content-between">
					<el-form-item label="备注" style="width: 40%;">
						<el-input type="textarea" :rows="5" v-model="Information.Remarks " placeholder="1000个字以内"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
					<el-form-item label="性格特点" style="width: 40%;">
						<el-input type="textarea" v-model="Information.Character " placeholder="1000个字以内" :rows="5"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
				</div>

				<div class="flex justify-content-between">
					<el-form-item label="成长环境" style="width: 40%;">
						<el-input type="textarea" v-model="Information.Environment " placeholder="1000个字以内" :rows="5"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
					<el-form-item label="特殊需求" style="width: 40%;">
						<el-input type="textarea" v-model="Information.SpecialNeeds " placeholder="1000个字以内" :rows="5"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
				</div>
				<div class="flex justify-content-between">
					<el-form-item label="进门成绩" style="width: 40%;">
						<el-input type="textarea" v-model="Information.OriginalResults " placeholder="1000个字以内"
							:rows="5" v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="入学测试" style="width: 40%;">
						<el-input type="textarea" v-model="Information.EntranceText " placeholder="1000个字以内" :rows="5"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit></el-input>
					</el-form-item>
				</div>
				<div class="flex justify-content-between">
					<el-form-item label="结课成绩" style="width: 40%;">
						<el-input type="textarea" v-model="Information.FinalGrade " placeholder="1000个字以内" :rows="5"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="期末考试成绩" style="width: 40%;">
						<el-input type="textarea" v-model="Information.FinalExam " placeholder="1000个字以内" :rows="5"
							v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
				</div>
				<div>
					<el-form-item label="报名入学时间" style="width: 40%;">
						<el-input type="textarea" v-model="Information.RegistrationTime " placeholder="1000个字以内"
							:rows="5" v-intelligentRecognition="{style:{right:'80px',bottom:'5px'}}" maxlength="1000"
							show-word-limit>
						</el-input>
					</el-form-item>
				</div>
			</el-form>

			<div class="flex justify-content-flexEnd margin-bottom-lg">
				<el-button class="cursor" type="primary" @click="submitForm('Information')" :loading="loadings" v-preventReClick="500">
					保存
				</el-button>
				<el-button class="cursor" @click="close">
					取消
				</el-button>
			</div>
		</el-tab-pane>
		<el-tab-pane label="EXCEL导入" name="second" v-if="false">

			<div class="text">暂未开放</div>
		</el-tab-pane>
		<el-tab-pane label="QQ导入" name="three" v-if="false">
			<div class="text">暂未开放</div>
		</el-tab-pane>
		<el-tab-pane label="微信导入" name="four" v-if="false">
			<div class="text">暂未开放</div>
		</el-tab-pane>
	</el-tabs>
</template>

<script>
	import addSource from '@/components/add-source'
	import addGroup from '@/components/add-group/addGroup.vue'
	export default {
		components:{
			addSource,
			addGroup
		},
		data() {
			return {
				editVisible:false,
				editVisible1:false,
				labelPosition: 'right',
				Name: 'first',
				loading: false,
				loadings:false,
				clientList: [], //客户标签列表
				datalist_enterprise: [], //分组列表
				Information: {
					UserName: '',
					Abbreviation: '',
					School: '',
					Grade: '',
					Sex: 1,
					Birthday: '',
					Source: '',
					Grouping: '',
					QQ: '',
					WeChat: '',
					PhoneNumber1: '',
					PhoneNumber2: '',
					FixedTelephone: '',
					Mail: '',
					WorkUnit: '',
					Address: '',
					Remarks: '',
					Character: '',
					Environment: '',
					SpecialNeeds: '',
					OriginalResults: '',
					EntranceText: '',
					FinalGrade: '',
					FinalExam: '',
					RegistrationTime: '',
					Progress: 1,
					CustomerType: 4,
				},
				rules: {
					UserName: [{
						required: true,
						message: '请输入客户名称',
						trigger: 'blur'
					}],
					School: [{
						required: true,
						message: '请输入就读学校',
						trigger: 'blur'
					}],
					PhoneNumber1:[{
						required: true,
						message: '请输入电话号码',
						trigger: 'blur'
					}],
					Grade:[{
						required: true,
						message: '请填写年级',
						trigger: 'blur'
					}],
					Source:[{
						required: true,
						message: '请选择来源',
						trigger: 'change'
					}]
				},
				listQuery: {
					PageIndex: 0,
					PageSize: 20,
					Sort: [{
						"Field": "id",
						"Type": 1
					}]
				},
				listQuery1: {
					HasRole: false,
					module: '',
					TotalCount: 0,
					PageIndex: 1,
					PageSize: 15
				},
				sourceList: [], //来源列表
			}
		},

		created() {
			this.getGroup()
			this.getSourceList()
		},
		methods: {
			getGroup(){
				this.API.customerSelectMyGroup(this.listQuery).then(res => {
					this.datalist_enterprise = res.data.rows
				})
			},
			editHandle() {
				this.editVisible = true;
				this.editVisible1=true;
			},
			closeEditPop() {
				this.editVisible = false;
				this.editVisible1=false;
				this.getSourceList()
				this.getGroup()
			},
			close() {
				// this.$router.push('/myClient/index')
			 
				this.$router.go(-1)
			},
			handleClicktab(tab, event) {},
			//选择客户标签
			isPhoneNumber(str) {
				// var myreg = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
				// if (!myreg.test(str)) {
				if (str.length !=11) {
					return false;
				} else {
					return true;
				}
			},
			email_blur(data) {
				var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
				if (!verify.test(data)) {
					return false;
				} else {
					return true
				}
			},
			chooselable(num,message) {
				if(num==0){
					this.$Dialog.ChooseCustomerlabel({
						num: 1,
					}).then(res => {
						this.clientList = res.split(",")
						this.Information.Label = res
						this.clientList.push(this.clientList.pop())
					})
				}else if(num==1){
					this.$Dialog.ChooseCustomerlabel({
						num: 2,
						message: message
					}).then(res => {
						this.clientList = res.split(",")
						this.Information.Label = res
						this.clientList.push(this.clientList.pop())
					})
				}
				
			},
			//获取客户来源列表
			getSourceList() {
				this.listQuery1.module = 'SaleOrigin';
				this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
					this.sourceList = res.data.rows;
					this.$forceUpdate()
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.Information.PhoneNumber1) {
							if (this.isPhoneNumber(this.Information.PhoneNumber1) == false) {
								this.$message.error("请输入正确的电话号码")
								return
							}
						}
						if (this.Information.PhoneNumber2) {
							if (this.isPhoneNumber(this.Information.PhoneNumber2) == false) {
								this.$message.error("请输入正确的电话号码")
								return
							}
						}
						if (this.Information.Mail) {
							if (this.email_blur(this.Information.Mail) == false) {
								this.$message.error("邮箱格式错误，请输入正确的邮箱号")
								return
							}
						} else {
							this.loadings=true
							this.API.postEditCustomer(this.Information).then(res => {
								if (res.code == 200) {
									this.loading = false
									this.loadings=false
									this.$message.success("创建用户成功")
									this.close()
								}
							})
						}

					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style scoped>
	>>>.el-form-item__label {
		line-height: 26px !important;
	}

	.Clientcenter {
		width: 68%;
		overflow-y: auto;
		height: 100%;
	}

	.text {
		font-size: 70px;
		color: #efefef;
		text-align: center;
		font-weight: bold;
		padding-top: 25%;
	}
</style>
